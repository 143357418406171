/*************
 * 01/07/2024 - Refactored to use auth0 to fetch an access token. As a token is valid for a user
 * and tenant combination, we will refresh oiur access token when we change tenant
 * So auth0 will take care of fetching the new token or do the refresk in case
 * the access token is no longer valid
 */
import { TenantContext, UserContext } from "@mtb/mtb-core";
import {
  AuthenticateService,
  EnumLanguages,
  EnumUserRole,
  InSufficientTenantRights,
  rolesCheck,
} from "@mtb/mtb-requests";
import React, {
  FC,
  ReactChild,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { Loader } from "@mtb/mtb-ui";
import { matchPath, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

type TenantContextLoaderProps = {
  children: ReactChild;
};

export const TenantContextLoader: FC<TenantContextLoaderProps> = React.memo(
  ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<{
      errorTitle: string;
      errorText: string;
    } | null>(null);
    const { t } = useTranslation("tenants");
    const { tenant, setTenant } = useContext(TenantContext);
    const { user } = useContext(UserContext);
    const location = useLocation();
    const { tenantKey: tenantKeyOnUrl } = matchPath(
      "/tenants/:tenantKey",
      location.pathname
    )?.params || { tenantKey: "" };
    const { getAccessTokenSilently } = useAuth0();

    const switchTenant = useCallback(async (key: string | null) => {
      console.log("TenantContextLoader -> switchTenant", key);
      if (key) {
        setLoading(true);
        setError(null);

        // tenant has changed, so we need to load a new access token
        const token = await getAccessTokenSilently({
          authorizationParams: {
            tenant: tenantKeyOnUrl,
          },
          cacheMode: "off",
        });
        console.log("TenantContextLoader -> token fetched -> token", token);
        try {
          const authSvc = new AuthenticateService();
          authSvc.setAuth0AccessToken(token);
          const result = await authSvc.SwitchTenant(key);
          console.log("TenantContextLoader -> switchTenant -> result", result);
          if (result) {
            if (
              rolesCheck(result.userRoleInTenant as EnumUserRole, [
                EnumUserRole.ADMIN,
                EnumUserRole.TENANTADMIN,
                EnumUserRole.TENANTCONTRIBUTOR,
                EnumUserRole.OWNER,
              ])
            ) {
              // make sure the fallback language is the first
              const tenantAppUrl = new URL(
                result.tenantKey + "/",
                process.env.NX_REACT_APP_URL!.toString()
              ).toString();
              console.log("TenantContextLoader -> setTenant", result.tenantKey);
              setTenant({
                tenantKey: result.tenantKey,
                validSubscription: result.validSubscription!,
                subscriptionExpireInDays: result.subscriptionExpiresInDays,
                userRoleInTenant: result.userRoleInTenant,
                supportedLngs: result.supportedLngs || [EnumLanguages.en],
                fallBackLanguage: result.fallBackLanguage || EnumLanguages.en,
                tenantAppUrl: tenantAppUrl,
              });
            } else {
              setTenant(null);
            }
          }
        } catch (ex) {
          if (ex instanceof InSufficientTenantRights) {
            setError({
              errorTitle: t("tenantinfo.alertfail"),
              errorText: t("tenantinfo.rightserror"),
            });
          } else if (ex.constructor.name === "InvalidTokenError") {
            setError({
              errorTitle: t("tenantinfo.alertfail"),
              errorText: t("tenantinfo.invalidtenant", { key: tenantKeyOnUrl }),
            });
          } else {
            setError({
              errorTitle: t("tenantinfo.alertfail"),
              errorText: t("tenantinfo.switcherror"),
            });
          }
          setTenant(null);
        }
        setLoading(false);
      } else {
        setTenant(null);
        setLoading(false);
      }
    }, []);

    console.log(
      "TenantContextLoader -> tenantKeyOnUrl",
      tenantKeyOnUrl,
      tenant,
      user
    );
    useEffect(() => {
      if (!user) {
        setTenant(null);
        setLoading(false);
        return;
      }

      // if the tenant on URL is different from the one in context we use the URL one
      console.log("TenantContextLoader -> check switchTenant", tenantKeyOnUrl);
      if (tenantKeyOnUrl && tenant?.tenantKey !== tenantKeyOnUrl) {
        console.log("TenantContextLoader -> do switchTenant", tenantKeyOnUrl);
        switchTenant(tenantKeyOnUrl || "").catch((ex) => {
          console.error("switchTenant error - set tenant and user to null", ex);
          setTenant(null);
          setLoading(false);
        });
      } else {
        // if no tenant in url or on same tenant - do nothing
        setLoading(false);
      }
    }, [tenantKeyOnUrl, switchTenant, user]);

    if (loading) {
      return (
        <Center width="100%">
          <Loader />
        </Center>
      );
    }
    if (error) {
      return (
        <Box width="100%" flexDirection="column" padding="5">
          <Heading size="md" flex="1">
            {error.errorTitle}
          </Heading>
          <Text flex="1" color="red">
            {error.errorText}
          </Text>
        </Box>
      );
    }

    // ... loading done -> we are on the right tenant (in context) or there is no tenant
    return <>{children}</>;
  }
);
