import { mtbService } from "./mtbService";
import {
  ErrorCode,
  InSufficientTenantRights,
  InvalidTenantError,
  NotFoundError,
} from "../entities/Error/ErrorCodes";
import { IProgramDetailType } from "../entities/DTO-models";

export class ProgramService {
  public async saveProgramDay(
    tenantKey: string,
    programId: number,
    programDay: Date,
    data: IProgramDetailType
  ): Promise<void> {
    try {
      const url = `/tenant/${tenantKey}/program/${programId
        .toString()
        .trim()}/${programDay.getFullYear().toString().trim()}-${(
        programDay.getMonth() + 1
      )
        .toString()
        .trim()}-${programDay.getDate().toString().trim()}`;

      const formData = new FormData();
      formData.append("date", "2024-09-21"); //data.date.toISOString());
      formData.append("published", data.published!.toString());
      let i = 0;
      data.details?.forEach((elt) => {
        formData.append(
          `details[${i}][hourFrom][hour]`,
          elt.hourFrom!.hour!.toString()
        );
        formData.append(
          `details[${i}][hourFrom][minute]`,
          elt.hourFrom!.minute!.toString()
        );
        formData.append(
          `details[${i}][hourFrom][second]`,
          elt.hourFrom!.second!.toString()
        );
        if (elt.hourTo) {
          formData.append(
            `details[${i}][hourTo][hour]`,
            elt.hourTo.hour!.toString()
          );
          formData.append(
            `details[${i}][hourTo][minute]`,
            elt.hourTo.minute!.toString()
          );
          formData.append(
            `details[${i}][hourTo][second]`,
            elt.hourTo.second!.toString()
          );
        }
        elt.title && formData.append(`details[${i}][title]`, elt.title);
        elt.location &&
          formData.append(`details[${i}][location]`, elt.location);
        elt.remark && formData.append(`details[${i}][remark]`, elt.remark);
        i++;
      });
      await mtbService.postPutFormDataProtected("put", url, formData);
      return;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.InvalidProgramId:
          throw new NotFoundError();
        case ErrorCode.NotFound:
          throw new NotFoundError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  public async setPublishedStateForDate(
    tenantKey: string,
    programId: number,
    programDay: Date,
    published: boolean
  ): Promise<void> {
    try {
      const url = `/tenant/${tenantKey}/program/${programId
        .toString()
        .trim()}/${programDay.getFullYear().toString().trim()}-${(
        programDay.getMonth() + 1
      )
        .toString()
        .trim()}-${programDay.getDate().toString().trim()}/setPublishedState`;

      const formData = new FormData();
      formData.append("published", published!.toString());

      await mtbService.postPutFormDataProtected("post", url, formData);
      return;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.InvalidProgramId:
          throw new NotFoundError();
        case ErrorCode.NotFound:
          throw new NotFoundError();
        default:
          break;
      }
      throw errResponse;
    }
  }
}
